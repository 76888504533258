import styled from "@emotion/styled";
import Helmet from "react-helmet";
import { Button, ButtonProps } from "@mantine/core";
import { APP_NAME, SOCIAL_CARE } from "../../values";
import { Link, useNavigate } from "react-router-dom";
import socialCareLogo from "../../assets/logos/socialCarelogo.png";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import "./Sidebar.css";
import { useState } from "react";
import { Markdown } from "../markdown";
import { content } from "../../constants/content";


const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the wrapper takes at least the full viewport height */
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem 1rem;
  background: #000;
  z-index: 2; /* Ensure the header is above other content */
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  height: auto;
  max-width: 40px;
  margin-right: 8px;
`;

const AppName = styled.h2`
  color: white;
  font-weight: 400;
  margin: 0;
  font-family: "Montserrat";
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  background: #161616;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-left: auto;
  font-family: "Montserrat";
`;

const FooterContainer = styled.footer`
  background: #000;
  color: white;
  padding: 1rem;
  text-align: center;
  z-index: 2;
  margin-top: auto; 
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
`;

const SmallText = styled.p`
  font-size: 12px;
`;

const Container = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  max-height: calc(100vh - 120px);

  .inner {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
    font-weight: "Work Sans", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;

    * {
      color: white !important;
    }

    h1,
    h2 {
      border-bottom: thin solid rgba(255, 255, 255, 0.2);
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
  }

  /* Hide the scrollbar for Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  /* Hide the scrollbar for Firefox */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
`;

function HeaderButton(props: ButtonProps & { icon?: string; onClick?: any; children?: any }) {
  return (
    <Button 
        size="xs"
        variant={props.variant || "subtle"}
        onClick={props.onClick}
        style={{ borderRadius: '5px', width: '110px', fontFamily: 'Helvetica, Arial, sans-serif' }}
    >
      {props.icon && <i className={"fa fa-" + props.icon} />}
      {props.children && <span>{props.children}</span>}
    </Button>
  );
}

export default function HomePage(props: any) {
  
    const navigate = useNavigate();
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const handleLogin = () => {
        navigate("/login");
        toggleSidebar();
    };

    const handleRegister = () => {
        navigate("/register");
        toggleSidebar();
    }

    const handleServices = () => {
        navigate("/services");
        toggleSidebar();
    }

    const handleHome = () => {
        navigate("/home");
        toggleSidebar();
    }

    const handleContacts = () => {
        navigate("/contacts");
        toggleSidebar();
    }


  return (
    <PageWrapper>
      <HeaderContainer>
        <Helmet>
          <title>{props.title ? `${props.title} - ` : ""}{SOCIAL_CARE}</title>
        </Helmet>

        <LogoContainer>
          <Link style={{ textDecoration: 'none', color: 'white', display:'flex', alignItems: 'center'}} to="/">
            <LogoImage src={socialCareLogo} alt="Logo" style={{ borderRadius: '30px'}} />
            <AppName>{SOCIAL_CARE}</AppName>
          </Link>
        </LogoContainer>

        { !isSidebarOpen && <ButtonsContainer>
          <HeaderButton icon="sign-in" onClick={() => navigate("/login")} variant="light">Log In</HeaderButton>
          <HeaderButton icon="user-plus" onClick={() => navigate("/register")} variant="light">Sign Up</HeaderButton>
        </ButtonsContainer>}
        { !isSidebarOpen && 
            <i className="fa fa-bars" style={{ color: "white", fontSize: "24px", marginBottom: '4px'}} onClick={toggleSidebar}/>}
        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <i className="fa fa-bars" style={{ color: "white", paddingTop: '30px', fontSize: "24px"}} onClick={toggleSidebar}/>
            <div className="content" style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '10px'}}>
                <HeaderButton icon="home" onClick={handleHome} variant="light">Home</HeaderButton>
                <HeaderButton icon="globe" onClick={handleServices} variant="light">Services</HeaderButton>
                <HeaderButton icon="phone" onClick={handleContacts} variant="light">Contact</HeaderButton>
                <HeaderButton icon="sign-in" onClick={handleLogin} variant="light">Log In</HeaderButton>
                <HeaderButton icon="user-plus" onClick={handleRegister} variant="light">Sign Up</HeaderButton>
            </div>
        </div>
      </HeaderContainer>

      <ContentContainer>
        <Container>
        <Markdown content={content} className="inner" />
          <FooterContainer>
            <FooterContent>
              <SmallText>Contact: expert@thecarepro.co.uk</SmallText>
              <nav style={{ display: 'flex', justifyContent: 'center', gap: '10px'}}>
                <Link to="/terms">
                    <SmallText>Terms of Service</SmallText>
                </Link>
                <Link to="/privacy">
                    <SmallText>Privacy Policy</SmallText>
                </Link>
              </nav>
              <SocialMediaContainer>
                <FaFacebook size={20} color="#4267B2" />
                <FaTwitter size={20} color="#1DA1F2" />
                <FaInstagram size={20} color="#E4405F" />
              </SocialMediaContainer>
              <SmallText style={{ marginBottom: '8px '}}>&copy; {new Date().getFullYear()} {SOCIAL_CARE}. All rights reserved.</SmallText>
            </FooterContent>
          </FooterContainer>
        </Container>
      </ContentContainer>

      
    </PageWrapper>
  );
}
