// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messages {
  max-height: 100%;
  flex-grow: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.empty-message {
  flex-grow: 1;
  padding-bottom: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* font-family: "Work Sans", sans-serif; */
  font-family: "Montserrat";
  line-height: 1.7;
  gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/chat.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,0CAA0C;EAC1C,yBAAyB;EACzB,gBAAgB;EAChB,SAAS;AACX","sourcesContent":[".messages {\n  max-height: 100%;\n  flex-grow: 1;\n  overflow-y: scroll;\n  display: flex;\n  flex-direction: column;\n}\n\n.empty-message {\n  flex-grow: 1;\n  padding-bottom: 5vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  /* font-family: \"Work Sans\", sans-serif; */\n  font-family: \"Montserrat\";\n  line-height: 1.7;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
