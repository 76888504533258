import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./about.css";
import image1 from "../../assets/Archive/AboutUs1.png";
import image2 from "../../assets/Archive/AboutUs2.png";

function AboutUs() {
  return (
    <>
      <div className="container">
        <Row className="border1 d-flex parent-direction align-items-center">
          <Col xs={12} md={6} lg={5} className="offset-lg-1">
            <div className="d-flex  child-direction align-items-start">
              <div className="border1">
                <img
                  src={image1}
                  alt="Your Image"
                  className="img-fluid overlay-image"
                  style={{ maxHeight: "560px", width: "100%" }}
                />
              </div>
              <div className="about">
                <p className="my-5 lh-base fw-lighter about2 mt-3">
                  But we're more than just a software platform. We're a
                  dedicated support system, here to guide you through every step
                  of your care journey. Whether you're seeking guidance on
                  regulatory requirements, need assistance with training
                  content, or simply want to connect with like-minded
                  professionals, TheCarePro is your go-to resource.
                </p>
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column align-items-start"
            style={{ marginTop: "20px" }} // Add margin here
          >
            <div className="mt-md-6 mt-3">
              <h1 className="display-6 mb-1 heading1 fw-bolder">ABOUT US</h1>
              <p className="my-3 lh-base fw-lighter mb-3">
                Welcome to TheCarePro, your trusted partner in navigating the
                complexities of the social care landscape. Our dedicated team is
                passionate about revolutionising the way care is delivered by
                seamlessly integrating technology with compassionate care
                expertise.
              </p>
            </div>
            <div className="border1">
              <img
                src={image2}
                alt="Your Image"
                className="img-fluid overlay-image"
                style={{ maxHeight: "560px", width: "100%" }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AboutUs;
