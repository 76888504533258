import React from "react";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import getStartedLogo from "../../assets/logos/material-symbols_play-circle-outline-rounded.png";
import "./faq.css";
import { useNavigate } from "react-router-dom";

export default function FAQ() {
  const navigate = useNavigate();

  return (
    <div className="container-fluid mt-5">
      <div className="mb-5 center">
        <h1 className="heading1 fw-bolder">FREQUENTLY ASKED QUESTIONS</h1>
      </div>
      <MDBAccordion>
        <MDBAccordionItem
          className="mt-3 custom-accordion-item"
          collapseId={2}
          headerTitle="What is TheCarePro.co.uk TheCarePro?"
        >
          <div className="vertical-bar"></div>
          <div className="left-margin">
            <p>
              TheCarePro.co.uk TheCarePro is a pioneering service combining the
              knowledge of social care experts with advanced technology. It acts
              as your 24/7 virtual assistant, designed to enhance your
              efficiency and expertise in the social care sector.
            </p>
          </div>
        </MDBAccordionItem>
        <MDBAccordionItem
          className="mt-3 custom-accordion-item"
          collapseId={3}
          headerTitle="How can TheCarePro.co.uk assist in starting a care company?"
        >
          <div className="vertical-bar"></div>
          <div className="left-margin">
            <p>
              Our platform provides specialized Consultancy for Care Startups,
              covering essential areas like business planning, regulatory
              compliance, and operational setup. This service offers substantial
              financial savings and expert guidance compared to traditional
              consultancy.
            </p>
          </div>
        </MDBAccordionItem>
        <MDBAccordionItem
          className="mt-3 custom-accordion-item"
          collapseId={4}
          headerTitle="I already run a care company. How is this platform beneficial for me?"
        >
          <div className="vertical-bar"></div>
          <div className="left-margin">
            <p>
              For existing care providers, TheCarePro.co.uk offers General Care
              Consultancy to refine and elevate your services. This includes
              cost-effective strategies, compliance support, and enhancing
              client satisfaction, without the need to hire a full-time
              consultant.
            </p>
          </div>
        </MDBAccordionItem>
        <MDBAccordionItem
          className="mt-3 custom-accordion-item"
          collapseId={5}
          headerTitle="Can you explain the AI-Powered Insights feature?"
        >
          <div className="vertical-bar"></div>
          <div className="left-margin">
            <p>
              Our platform harnesses AI technology to analyze complex data and
              provide actionable insights. This feature acts like a virtual data
              analyst, offering strategic recommendations without the expense of
              hiring a specialist.
            </p>
          </div>
        </MDBAccordionItem>
        <MDBAccordionItem
          className="mt-3 custom-accordion-item"
          collapseId={6}
          headerTitle="What are the subscription costs?"
        >
          <div className="vertical-bar"></div>
          <div className="left-margin">
            <p>
              Our subscription model is designed to be cost-effective, offering
              access to comprehensive resources and expert assistance for less
              than the cost of two hours of professional consultancy. This
              ensures you get exceptional value and support. Cancel at anytime <a role="button" onClick={() => navigate("/viewPackages")}>
              <u className="cursor" style={{color:"purple"}}>see pricing here</u>
            </a>
            </p>
          </div>
        </MDBAccordionItem>
        <MDBAccordionItem
          className="mt-3 custom-accordion-item"
          collapseId={7}
          headerTitle="How secure is my data with TheCarePro.co.uk?
          "
        >
          <div className="vertical-bar"></div>
          <div className="left-margin">
            <p>
              Data security is a top priority. We employ robust measures to
              safeguard your information, ensuring confidentiality and adherence
              to the highest industry standards of data protection and privacy.
            </p>
          </div>
        </MDBAccordionItem>
        <MDBAccordionItem
          className="mt-3 custom-accordion-item"
          collapseId={8}
          headerTitle="How can I get more information about TheCarePro.co.uk?"
        >
          <div className="vertical-bar"></div>
          <div className="left-margin">
            <p>
              For further inquiries or detailed information, please contact us
              via the "Contact Us" section on our website. Our team is dedicated
              to providing prompt and helpful responses to all your queries.
            </p>
          </div>
        </MDBAccordionItem>
        <MDBAccordionItem
          className="mt-3 custom-accordion-item"
          collapseId={9}
          headerTitle="Do I need special software to access TheCarePro.co.uk?"
        >
          <div className="vertical-bar"></div>
          <div className="left-margin">
            <p>
              No special software is required. TheCarePro.co.uk is accessible
              through any standard web browser on any device with internet
              connectivity, making it convenient and user-friendly.
            </p>
          </div>
        </MDBAccordionItem>
        <MDBAccordionItem
          className="mt-3 custom-accordion-item"
          collapseId={10}
          headerTitle="Is there a free version of TheCarePro.co.uk?"
        >
          <div className="vertical-bar"></div>
          <div className="left-margin">
            <p>
              Yes, we offer a free version of our service. This allows you to
              explore the benefits and features of our platform before opting
              for a subscription.
            </p>
          </div>
        </MDBAccordionItem>
        <MDBAccordionItem
          className="mt-3 custom-accordion-item"
          collapseId={11}
          headerTitle="What if I need assistance while using the platform?
          "
        >
          <div className="vertical-bar"></div>
          <div className="left-margin">
            <p>
              Our dedicated customer support team is always ready to assist.
              Whether you have questions or require help with any aspect of our
              platform, you can reach out to us at any time for prompt support.
            </p>
          </div>
        </MDBAccordionItem>
      </MDBAccordion>
    </div>
  );
}
