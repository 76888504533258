import styled from "@emotion/styled";
import { Button, ActionIcon, Textarea } from "@mantine/core";
import { Tooltip } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppContext } from "../context";
import { useAppDispatch, useAppSelector } from "../store";
import { selectMessage, setMessage } from "../store/message";
import { selectTemperature } from "../store/parameters";
import {
  openSystemPromptPanel,
  openTemperaturePanel,
} from "../store/settings-ui";
import SimpleRecorder from "./SimpleRecorder";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import { toast } from "react-hot-toast";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { SOCIAL_CARE } from "../values";

const Container = styled.div`
  background: #292933;
  border-top: thin solid #393933;
  padding: 1rem 0 0 0;

  .inner {
    max-width: 50rem;
    margin: auto;
    text-align: right;
  }

  .settings-button {
    margin: 0.5rem -0.4rem 0.5rem 1rem;
    font-size: 0.7rem;
    color: #999;
  }
`;


const FooterContainer = styled.footer`
  background: #000;
  color: white;
  padding: 1rem;
  text-align: center;
  z-index: 2;
  margin-top: auto; 
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
`;

const SmallText = styled.p`
  font-size: 12px;
`;

const StyledFab = styled(Fab)`
  position: relative;
  
  background-color: #272727;
  color: white;
  opacity: 1;
  margin: 12px;
  z-index: 3;
  transition: all 0.2s;
  :hover {
    background-color: #272727;
    opacity: 0.9;
  }
`;

export declare type OnSubmit = (name?: string) => Promise<boolean>;

function PaperPlaneSubmitButton(props: { onSubmit: any; disabled?: boolean }) {
  return (
    <Tooltip title="Send Message" aria-label="record" placement="right">
      <StyledFab onClick={props.onSubmit} style={{backgroundColor:"black",color:"white"}} aria-label="record">
        <SendIcon fontSize="small" />
      </StyledFab>
    </Tooltip>
  );
}
function MicButton(props: { onSubmit: any; disabled?: boolean }) {
  return (
    <ActionIcon
      size="sm"
      disabled={props.disabled}
      loading={props.disabled}
      onClick={props.onSubmit}
    >
      <i className="fa fa-microphone" style={{ fontSize: "90%" }} />
    </ActionIcon>
  );
}

export interface MessageInputProps {
  disabled?: boolean;
}

export default function MessageInput(props: MessageInputProps) {
  const temperature = useAppSelector(selectTemperature);
  const message = useAppSelector(selectMessage);
  const [audioLoading, setAudioLoading] = useState(false);

  const context = useAppContext();
  const dispatch = useAppDispatch();

  const onCustomizeSystemPromptClick = useCallback(
    () => dispatch(openSystemPromptPanel()),
    [dispatch]
  );
  const onTemperatureClick = useCallback(
    () => dispatch(openTemperaturePanel()),
    [dispatch]
  );
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      dispatch(setMessage(e.target.value));
    },
    [dispatch]
  );

  const pathname = useLocation().pathname;

  const checkUserType = async () => {
    try {
      const token = localStorage.getItem('token');
      console.log(">>>>",token)
      const userId = localStorage.getItem('userId');
      const apiUrl = `https://api.thecarepro.co.uk/api/v1/account/status/${userId}`;
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      };
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
  
      return response.json();
    } catch (error) {
      toast.error('An unexpected error occurred. Please try again later.');
    }
  }

  const checkUserTokenCount = async () => {
    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const apiUrl = `https://api.thecarepro.co.uk/api/v1/tokenization/${userId}`;
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      };
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
  
      return response.json();
    } catch (error) {
      toast.error('An unexpected error occurred. Please try again later.');
    }
  }

  const addTokenForTrial = async () => {
    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const apiUrl = `https://api.thecarepro.co.uk/api/v1/tokenization/${userId}`;
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      };
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
  
      return response.json();
    } catch (error) {
      toast.error('An unexpected error occurred. Please try again later.');
    }
  }

  const getVouchersStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      const apiUrl = `https://api.thecarepro.co.uk/api/v1/voucher/status/undone`;
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      };
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
  
      return response.json();
    } catch (error) {
      toast.error('An unexpected error occurred. Please try again later.');
    }
  }
  const onSubmit = useCallback(async () => {
    const voucherStatus = await getVouchersStatus()
    if(voucherStatus?.payload == true){
      if (await context.onNewMessage(message)) {
        dispatch(setMessage(""));
      }
    }else{
      const userType = await checkUserType();
      if(userType?.payload?.Free){
        const userTokenCount = await checkUserTokenCount();
        if(userTokenCount?.payload?.tokenCount < 7){
          await addTokenForTrial();
          if (await context.onNewMessage(message)) {
            dispatch(setMessage(""));
          }
        }else{
          toast.error('Free trial limit exceed');
        }
      }else{
        if (await context.onNewMessage(message)) {
          dispatch(setMessage(""));
        }
      }
    }
   
  }, [context, message, dispatch]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && e.shiftKey === false && !props.disabled) {
        e.preventDefault();
        onSubmit();
      }
    },
    [onSubmit, props.disabled]
  );

  const rightSection = useMemo(() => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          position: "absolute",
          right: 0,
          paddingRight: "1rem",
        }}
      >
        <SimpleRecorder />

        {/* <MicButton onSubmit={onSubmit} disabled={props.disabled} /> */}
        <PaperPlaneSubmitButton onSubmit={onSubmit} disabled={props.disabled} />
      </div>
    );
  }, [onSubmit, props.disabled]);

  const messagesToDisplay = context.currentChat.messagesToDisplay;
  const disabled =
    context.generating ||
    messagesToDisplay[messagesToDisplay.length - 1]?.role === "user" ||
    (messagesToDisplay.length > 0 &&
      !messagesToDisplay[messagesToDisplay.length - 1]?.done);

  const isLandingPage = pathname === "/";
  if (context.isShare || (!isLandingPage && !context.id)) {
    return null;
  }

  return (
    <Container style={{background:"#FFFFFF",borderTop:"3px solid white"}}>
      <div className="inner p-2">
        <Textarea
          disabled={props.disabled || disabled}
          autosize
          minRows={3}
          maxRows={12}
          placeholder={"Enter a message here..."}
          value={message}
          onChange={onChange}
          rightSection={rightSection}
          onKeyDown={onKeyDown}
          style={{ position: "relative" }}
        />
        <div>
          <div style={{ height: "20px" }}></div>
          {/* <Button variant="subtle"
                    className="settings-button"
                    size="xs"
                    compact
                    onClick={onCustomizeSystemPromptClick}>
                    <span>Customize system prompt</span>
                </Button>
                <Button variant="subtle"
                    className="settings-button"
                    size="xs"
                    compact
                    onClick={onTemperatureClick}>
                    <span>Temperature: {temperature.toFixed(1)}</span>
                </Button> */}
        </div>
      </div>
      {/* <FooterContainer>
            <FooterContent>
              <SmallText>Contact: expert@thecarepro.co.uk</SmallText>
              <nav style={{ display: 'flex', justifyContent: 'center', gap: '10px'}}>
                <Link to="/terms">
                    <SmallText>Terms of Service</SmallText>
                </Link>
                <Link to="/privacy">
                    <SmallText>Privacy Policy</SmallText>
                </Link>
              </nav>
              <SocialMediaContainer>
                <FaFacebook size={20} color="#4267B2" />
                <FaTwitter size={20} color="#1DA1F2" />
                <FaInstagram size={20} color="#E4405F" />
              </SocialMediaContainer>
              <SmallText style={{ marginBottom: '8px '}}>&copy; {new Date().getFullYear()} {SOCIAL_CARE}. All rights reserved.</SmallText>
            </FooterContent>
          </FooterContainer> */}
    </Container>
  );
}
