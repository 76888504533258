import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { AppContextProvider } from "./context";
import store, { persistor } from "./store";
import LandingPage from "./components/pages/landing";
import ChatPage from "./components/pages/chat";
import AboutPage from "./components/pages/about";
import { ProSidebarProvider } from "react-pro-sidebar";
import "./index.scss";
import Notes from "./components/pages/notes";
import { Toaster } from "react-hot-toast";
import ServicesPage from "./components/pages/services";
import Subscription from "./components/pages/subscription";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import HomePageNew from "./components/pages/homepage";
import SignUpScreen from "./components/pages/sign-up";
import SignInScreen from "./components/pages/sign-in";
import ViewPackages from "./components/pages/view-packages";
import ContactUs from "./components/pages/contactUs";
import EmailVerify from "./components/emailVerify";
import { GoogleOAuthProvider } from '@react-oauth/google';



const stripePromise = loadStripe("pk_test_51OLRDwC3lZYN78bromNzm0JVlrRhLFOUPhYmG6ftouPqWREQL3AjxAjNzxbXEdvzyMrVzEdPg5a0f7KZLLXCPxJ100U9zEBfsC");


const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AppContextProvider>
        <LandingPage landing={true} />
      </AppContextProvider>
    ),
  },
  {
    path: "/notes",
    element: (
      <AppContextProvider>
        <Notes />
      </AppContextProvider>
    ),
  },
  {
    path: "/chat/:id",
    element: (
      <AppContextProvider>
        <ChatPage />
      </AppContextProvider>
    ),
  },
  {
    path: "/s/:id",
    element: (
      <AppContextProvider>
        <ChatPage share={true} />
      </AppContextProvider>
    ),
  },
  {
    path: "/s/:id/*",
    element: (
      <AppContextProvider>
        <ChatPage share={true} />
      </AppContextProvider>
    ),
  },
  {
    path: "/about",
    element: (
      <AppContextProvider>
        <AboutPage />
      </AppContextProvider>
    ),
  },
  {
    path: "/subscription",
    element: (
        // <Elements stripe={stripePromise}>
      <AppContextProvider>
          <Subscription />
      </AppContextProvider>
        // </Elements>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
      // <Elements stripe={stripePromise}>
  <React.StrictMode>
    <GoogleOAuthProvider clientId="170922838955-ltijr0hrufllsom6gfrkluhv4qki7h9b.apps.googleusercontent.com">
    <MantineProvider >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ProSidebarProvider>
            <ModalsProvider>
              {JSON.parse(
                localStorage.getItem("cureSpaceUserLogged") || "false"
              ) ? (
                <RouterProvider router={router} />
              ) : (
                <BrowserRouter>
                  <Routes>
                    <Route path="/register" element={<SignUpScreen />} />
                    <Route path="/login" element={<SignInScreen />} />
                    <Route path="/services" element={<ServicesPage />} />
                    <Route path="/viewPackages" element={<ViewPackages />} />
                    <Route path="/home" element={<HomePageNew />} />
                    <Route path="/contactUs" element={<ContactUs />} />
                    <Route path="*" element={<HomePageNew />} />
                    <Route path="/verify/:token" element={<EmailVerify />} />

                  </Routes>
                </BrowserRouter>
              )}
              <Toaster position="top-center" reverseOrder={false} />
            </ModalsProvider>
          </ProSidebarProvider>
        </PersistGate>
      </Provider>
    </MantineProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
        // </Elements>
);