// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    position: fixed;
    top: 0;
    right: -250px;
    width: 250px;
    height: 100vh;
    background-color: #000;
    color: white;
    transition: right 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sidebar.open {
    right: 0;
  }
  
  .drawer-icon {
    cursor: pointer;
    font-size: 24px;
    margin: 10px;
  }
  
  .content {
    flex: 1 1;
    padding: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/pages/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,aAAa;IACb,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,kCAAkC;IAClC,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,QAAQ;EACV;;EAEA;IACE,eAAe;IACf,eAAe;IACf,YAAY;EACd;;EAEA;IACE,SAAO;IACP,aAAa;EACf","sourcesContent":[".sidebar {\n    position: fixed;\n    top: 0;\n    right: -250px;\n    width: 250px;\n    height: 100vh;\n    background-color: #000;\n    color: white;\n    transition: right 0.3s ease-in-out;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  \n  .sidebar.open {\n    right: 0;\n  }\n  \n  .drawer-icon {\n    cursor: pointer;\n    font-size: 24px;\n    margin: 10px;\n  }\n  \n  .content {\n    flex: 1;\n    padding: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
