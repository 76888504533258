import React, { useEffect, useMemo, useState } from "react";
import "../pages/homepage.css";
import styled from "@emotion/styled";
import logo from "../../assets/logos/thecareprocouk-high-resolution-logo-transparent (1).png";
import Footer from "./footer";
import { FaFacebook, FaInstagram, FaYoutube, FaTiktok } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { SOCIAL_CARE } from "../../values";
import FAQ from "./faq";
import OurServices from "./our-services";
import AboutUs from "./aboutUs";
import MyCarousel from "./carousal";
import { Textarea } from "@mantine/core";
import { Tooltip } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import Fab from "@material-ui/core/Fab";
import SimpleRecorder from "../SimpleRecorder";

const VideoContainer = styled.div`
  margin-top: 20px;
`;

const FooterContainer = styled.footer`
  background: #000;
  color: white;
  padding: 1rem;
  text-align: center;
  z-index: 2;
  margin-top: auto;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: center;
`;

const StyledFab = styled(Fab)`
  position: relative !important;
  height: 30px !important;
  width: 36px !important;
  background-color: #272727 !important;
  color: white !important;
  opacity: 1 !important;
  margin: 12px !important;
  z-index: 3 !important;
  transition: all 0.2s !important;
  :hover {
    background-color: #272727 !important;
    opacity: 0.9 !important;
  }
`;

function HomePageNew() {
  useEffect(() => {
    // Suppress the warning for OwlCarousel
    console.warn = () => {};
  }, []);

  const [expanded, setExpanded] = useState(false);

  const toggleNav = () => {
    setExpanded(!expanded);
  };

  function PaperPlaneSubmitButton(props: {
    onSubmit: any;
    disabled?: boolean;
  }) {
    return (
      <Tooltip title="Send Message" aria-label="record" placement="right">
        <StyledFab
          onClick={props.onSubmit}
          color="secondary"
          aria-label="record"
        >
          <SendIcon fontSize="small" />
        </StyledFab>
      </Tooltip>
    );
  }

  const rightSection = useMemo(() => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          position: "absolute",
          right: 0,
          paddingRight: "0rem",
        }}
      >
        <SimpleRecorder />

        {/* <MicButton onSubmit={onSubmit} disabled={props.disabled} /> */}
        <PaperPlaneSubmitButton
          onSubmit={() => navigate("/register")}
          disabled={false}
        />
      </div>
    );
  }, []);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [isServiceActive, setServiceActive] = useState(false);
  const [isContactActive, setContactActive] = useState(false);
  const [isHomeActive, setHomeActive] = useState(false);

  function scrollToAbout(event) {
    event.preventDefault(); // Prevent the default behavior of the link
    const aboutSection = document.getElementById("about-us");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
      setIsActive(true);
      setServiceActive(false);
      setContactActive(false);
      setHomeActive(false);
    }
  }
  function scrollToServices(event) {
    event.preventDefault(); // Prevent the default behavior of the link
    const aboutSection = document.getElementById("services");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
      setServiceActive(true);
      setIsActive(false);
      setContactActive(false);
      setHomeActive(false);
    }
  }
  function scrollToContact(event) {
    event.preventDefault(); // Prevent the default behavior of the link
    const aboutSection = document.getElementById("contact-us");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
      setContactActive(true);
      setServiceActive(false);
      setIsActive(false);
      setHomeActive(false);
    }
  }
  function scrollToHome(event) {
    event.preventDefault(); // Prevent the default behavior of the link
    const aboutSection = document.getElementById("home");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
      setHomeActive(true);
      setContactActive(false);
      setServiceActive(false);
      setIsActive(false);
    }
  }

  return (
    <div>
      <div className="hero-section">
        <div className="container-fluid ">
          {" "}
          <header>
            <nav
              className={`navbar navbar-expand-lg navbar-dark bg-${
                expanded ? "purple" : "transparent"
              } p-3`}
            >
              <div className="container-fluid">
                <a className="navbar-brand" href="#">
                  <img
                    src={logo}
                    className="nav-logo"
                    width={"100px"}
                    height={"75px"}
                    alt=""
                  />
                </a>
                <button
                  className={`navbar-toggler ${
                    expanded ? "purple-toggler" : ""
                  }`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={toggleNav}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <a
                        role="button"
                        className={`nav-link montserrat fs-5 ${
                          isHomeActive ? "active" : ""
                        }`}
                        onClick={scrollToHome}
                      >
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        role="button"
                        className={`nav-link montserrat fs-5 ${
                          isActive ? "active" : ""
                        }`}
                        onClick={scrollToAbout}
                      >
                        About
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        role="button"
                        className={`nav-link montserrat fs-5 ${
                          isServiceActive ? "active" : ""
                        }`}
                        onClick={scrollToServices}
                      >
                        Services
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        role="button"
                        className={`nav-link montserrat fs-5 ${
                          isContactActive ? "active" : ""
                        }`}
                        onClick={() => navigate("/contactUs")}
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                  <div className="d-flex">
                    <button
                      className="outlined-purple me-2"
                      onClick={() => navigate("/login")}
                    >
                      Sign In
                    </button>
                    <button
                      className="purple"
                      onClick={() => navigate("/register")}
                    >
                      Sign Up
                    </button>
                  </div>
                </div>
              </div>
            </nav>
          </header>
        </div>

        <section id="home" className="jumbotron">
          <div className="d-flex flex-column align-items-center gap-4">
            <h1 className="text-center display-1 display-sm-5 fw-bolder montserrat">
              Empowering Care, Guided By Technology
            </h1>
            <p className="fs-5 text-white fw-lighter montserrat">
              Simplify care management and elevate your operations with
              TheCarePro.
            </p>

            <Textarea
              disabled={false}
              autosize
              minRows={2}
              maxRows={2}
              placeholder={
                "Welcome to Thecarepro.co.uk. 🌟 How can we assist you in providing exceptional care today?"
              }
              rightSection={rightSection}
              style={{
                width: "60%",
                position: "relative",
                borderRadius: "10px",
              }}
              className="text-area"
            />
          </div>

          <section className="chat mb-4">
            <VideoContainer>
              <iframe
                width="100%"
                height="415"
                src="https://www.youtube.com/embed/r77SVmY9uqg"
                title="Embedded Video"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </VideoContainer>
          </section>
        </section>
      </div>

      <section
        id="about-us"
        className="aboutUs"
        style={{ background: "#fbf4ff", height: "1000px" }}
      >
        <AboutUs></AboutUs>
      </section>

      <section id="services" className="services">
        <OurServices></OurServices>
      </section>
      <section>
        <MyCarousel></MyCarousel>
      </section>
      <section>
        <div className="container-fluid p-5">
          <FAQ></FAQ>
        </div>
      </section>
      <section id="contact-us" className="footer">
        <div className="container-fluid p-2">
          <Footer></Footer>
        </div>
      </section>
      <section>
        <FooterContainer>
          <FooterContent>
            <nav
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <Link to="/terms">
                <div>
                  <u className="footer-text text-white fs-6">
                    Terms of Service
                  </u>
                </div>
              </Link>
              <Link to="/privacy">
                <div>
                  <u className="footer-text">Privacy Policy</u>
                </div>
              </Link>
            </nav>
            <SocialMediaContainer style={{ gap: "1.5rem" }} className="mt-3">
              <a
                href="https://www.facebook.com/thecarepro"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook size={25} color="#4267B2" />
              </a>
              {/* <FaTwitter size={25} color="#1DA1F2" /> */}
              <a
                href="https://www.tiktok.com/@thecarepro.co.uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTiktok size={24} />
              </a>

              <FaInstagram size={25} color="#E4405F" />
              <a
                href="https://youtube.com/@thecarepro1"
                target="_blank"
                rel="noopener noreferrer"
              ><FaYoutube size={25} color="#E4405F" /></a>
              
            </SocialMediaContainer>
            <hr className="horizontal-bar" />

            <div style={{ marginBottom: "8px " }} className="montserrat">
              &copy; {new Date().getFullYear()} {SOCIAL_CARE}. All rights
              reserved.
            </div>
          </FooterContent>
        </FooterContainer>
      </section>
    </div>
  );
}

export default HomePageNew;
