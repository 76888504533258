import React from "react";
import "./Footer.css"; // Assuming you have a CSS file named Footer.css in the same directory
import image from "../../assets/logos/wallpaperflare.com_wallpaper (2).jpg";

const Footer = () => {
  return (
    <div>
      <div className="container-fluid p-5">
        <div className="row no-gutters bg">
          <div className="col-md-8 col-lg-6 col-sm-12">
            <div className="d-flex align-items-center h-100 text-white text-md-left pl-5">
              <div className="content">
                <h1 className="heading fw-bolder fs-md-4">GET TO KNOW MORE ABOUT US</h1>
                <p style={{color:'white'}} className="mt-3">
                  Address. TheCarePro Limited, Suite 4, 62 Portman Road,
                  Reading, Berkshire. RG30 1EA. Then in the footer company
                  registration Company number 15545146
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-6 col-sm-12 d-flex flex-column align-items-center justify-content-center button">
            <button className="purple text-white py-2 mt-5">Contact Us</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
