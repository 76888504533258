import "./subscription.css";
import React, { useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import logo from "../../assets/logos/thecareprocouk-high-resolution-logo-transparent (1).png";
import { Popover, Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import styled from "@emotion/styled";
import { SOCIAL_CARE } from "../../values";
import { Fab } from "@material-ui/core";

const FooterContainer = styled.footer`
  background: #000;
  color: white;
  padding: 1rem;
  text-align: center;
  z-index: 2;
  margin-top: auto;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: center;
`;
const SmallText = styled.p`
  font-size: 12px;
  font-family: "Montserrat";
`;

const SocialMediaIcon = styled.div`
  background-color: rgba(
    255,
    255,
    255,
    0.192
  ); /* Adjust transparency as needed */
  border-radius: 50%;
  padding: 10px; /* Adjust padding as needed */
`;

const StyledFab = styled(Fab)`
  position: relative !important;
  height: 30px !important;
  width: 36px !important;
  background-color: #272727 !important;
  color: white !important;
  opacity: 1 !important;
  margin: 12px !important;
  z-index: 3 !important;
  transition: all 0.2s !important;
  :hover {
    background-color: #272727 !important;
    opacity: 0.9 !important;
  }
`;

function ViewPackages() {
  const navigate = useNavigate();

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Add voucher to Get Discount!
    </Tooltip>
  );

  return (
    <>
      <div className="container-fluid">
        <div className="row p-5">
          <div className="col-lg-8 col-md-8 offset-md-2 offset-lg-2">
            <iframe
              width="100%"
              height="415"
              src="https://www.youtube.com/embed/eN3oTx6Wvss"
              title="Embedded Video"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <Row className="text-center">
          <Col md={5} className="offset-md-1  mt-3 offset-xl-2 col-md-4 col-xl-4">
            <Card
              className="d-flex align-items-center h-100"
              style={{ backgroundColor: "#FAF3FE" }}
            >
              {/* <Card.Img src={logo} width={"150px"} height={"150px"}/> */}
              <div className="col-xl-6 col-lg-9 col-md-7 p-4">
                <img src={logo} className="img-fluid" alt="" />
              </div>
              <Card.Body>
                <Card.Title className="fs-3 fw-bolder">
                  CarePro Connect - Lite
                </Card.Title>

                <div className="fs-5 fw-lighter m-2 block px-3 py-2">
                  <li className="mt-3">
                    Start your data adventure with 7 QUIERIES PER WEEK !
                  </li>
                  <li className="mt-3">
                    Discover trends, patterns, and insights effortlessly.
                  </li>
                  <li className="mt-3">
                    Access our exclusive query library and stay ahead 24 hours a
                    day.
                  </li>
                  <li className="mt-3">
                    Supercharge your data skills and uncover hidden gems.
                  </li>
                  <li className="mt-3">
                    Reinforce your administration care team
                  </li>
                </div>
                {/* <Card.Subtitle className="mb-5 mt-4 fw-bolder fs-4">
                Free
              </Card.Subtitle> */}
              </Card.Body>
            </Card>
          </Col>
          <Col md={5} xl={4} className="mt-3">
            <Card
              className="d-flex align-items-center h-100"
              style={{ backgroundColor: "#FAF3FE" }}
            >
              <div className="col-xl-6 col-lg-9 col-md-7 p-4 ">
                <img src={logo} className="img-fluid" alt="" />
              </div>
              <Card.Body>
                <Card.Title className="fs-3 fw-bolder">
                  CarePro Connect - Essentials
                </Card.Title>
                <div className="fs-5 fw-lighter m-2 block px-3 py-2">
                  <li className="mt-3">
                    UNLOCK UNLIMITED INSIGHTS for your data journey!
                  </li>
                  <li className="mt-3">
                    {" "}
                    Dive deep into data and make informed decisions.
                  </li>
                  <li className="mt-3">
                    {" "}
                    Find trends, patterns, and opportunities with ease.
                  </li>
                  <li className="mt-3">
                    Dominate your industry with unlimited data exploration at
                    your finger tips.
                  </li>
                  <li className="mt-3">
                    Join our supportive community and conquer data together!
                  </li>
                </div>

                {/* <Card.Subtitle className="mb-3 mt-3 fs-4 fw-bolder">
                $10/mo &nbsp;
                <OverlayTrigger placement="right" overlay={renderTooltip}>
                  <Button className="purple rounded-circle">+</Button>
                </OverlayTrigger>
              </Card.Subtitle> */}

                {/* <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                <Button variant="success">+</Button>
              </OverlayTrigger> */}
                {/* <Button className="purple">Pay Now</Button> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div
          className="row text-center col-md-2 col-sm-1 offset-md-5 offset-4 col-4 mt-2 mb-5"
          onClick={() => navigate("/register")}
        >
          <Button className="purple mx-auto py-2 mt-5">Go to sign up</Button>
        </div>
      </div>
      <section>
        <FooterContainer>
          <FooterContent>
            <nav
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <Link to="/terms">
                <div>
                  <u className="footer-text text-white fs-6">
                    Terms of Service
                  </u>
                </div>
              </Link>
              <Link to="/privacy">
                <div>
                  <u className="footer-text">Privacy Policy</u>
                </div>
              </Link>
            </nav>
            <SocialMediaContainer style={{ gap: "1.5rem" }} className="mt-3">
            <a
                href="https://www.facebook.com/thecarepro"
                target="_blank"
                rel="noopener noreferrer"
              >
              <FaFacebook size={25} color="#4267B2" />

              </a>
              {/* <FaTwitter size={25} color="#1DA1F2" /> */}
              <a
                href="https://www.tiktok.com/@thecarepro.co.uk"
                target="_blank"
                rel="noopener noreferrer"
              ><FaTiktok size={24} /></a>
              
              <FaInstagram size={25} color="#E4405F" />
              <a
                href="https://youtube.com/@thecarepro1"
                target="_blank"
                rel="noopener noreferrer"
              ><FaYoutube size={25} color="#E4405F" /></a>
            </SocialMediaContainer>
            <hr className="horizontal-bar" />

            <div style={{ marginBottom: "8px " }} className="montserrat">
              &copy; {new Date().getFullYear()} {SOCIAL_CARE}. All rights
              reserved.
            </div>
          </FooterContent>
        </FooterContainer>
      </section>
    </>
  );
}

export default ViewPackages;
