import "./subscription.css";
import { useEffect, useState } from "react";
import { Card, Row, Col, Modal } from "react-bootstrap";
import logo from "../../assets/logos/thecareprocouk-high-resolution-logo-transparent (1).png";
import { Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Input } from "@mantine/core";
import { toast } from "react-hot-toast";

function PricingCards() {
  const schema = yup.object().shape({
    voucherCode: yup.string().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      voucherCode: "", // Set an initial value for the voucher code
    },
  });

  const [voucherApplied, setVoucherApplied] = useState(false); // State to track if the voucher is applied

  const onSubmitHandler = async (data) => {
    const userIdByStorage = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    let voucherObj = {
      voucherCode: data.voucherCode,
      userId: userIdByStorage,
    };

    // handleClose();
    try {
      const apiUrl = "https://api.thecarepro.co.uk/api/v1/voucher/redeem";
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(voucherObj),
      };
      const response = await fetch(apiUrl, requestOptions);
      if (response.ok) {
        // localStorage.setItem("cureSpaceUserLogged", JSON.stringify(true));
        toast.success("Voucher added successfully");
        setVoucherApplied(true);
        handleClose();
        window.location.reload();
        // navigate("/login");
      } else {
        const errorMessage = "Invalid Voucher! Try again";
        toast.error(errorMessage);
        handleClose();
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };
  const [show, setShow] = useState(false);

  // useEffect(() => {

  //   // setShow(!show)
  // }, [show])

  const handleClose = () => {
    setShow(!show);
  };
  const handleShow = () => {
    setShow(!show);
    reset();
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Add voucher to Get Discount!
    </Tooltip>
  );

  const sec_stripe = localStorage.getItem("token");

  const [product, setProduct] = useState({
    payload: {
      paymentId: null,
      productId: null,
    },
  });

  const [paymentLink, setPaymentLink] = useState("");

  const onToken = (token) => {
    fetch("https://api.thecarepro.co.uk/api/v1/stripe/createStripePayment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sec_stripe}`,
      },
      body: JSON.stringify({
        paymentId: product?.payload?.paymentId,
        productId: product?.payload?.productId,
      }),
    })
      .then((response) => response.json())
      .then((paymentData) => {
        console.log("Payment successful:", paymentData);
      })
      .catch((error) => console.error("Error creating payment:", error));
  };

  useEffect(() => {
    fetch(
      "https://api.thecarepro.co.uk/api/v1/stripe/createStripePaymentLink",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sec_stripe}`,
        },
        body: JSON.stringify({}),
      }
    )
      .then((response) => response.json())
      .then((data) => setPaymentLink(data?.payload?.paymentUrl))
      .catch((error) => console.error("Error creating payment link:", error));
  }, []);

  useEffect(() => {
    fetch("https://api.thecarepro.co.uk/api/v1/stripe/createStripeProduct", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sec_stripe}`,
      },
      body: JSON.stringify({
        name: "social care",
        description: "social care",
        images: [
          "https://audiobucket-chatgp3.s3.eu-north-1.amazonaws.com/public_images/WhatsApp+Image+2023-07-26+at+05.12.49.jpg",
        ],
      }),
    })
      .then((response) => response.json())
      .then((data) => setProduct(data))
      .catch((error) => console.error("Error fetching product:", error));
  }, []);

  return (
    <div className="container-fluid">
      <div className="row p-5">
        <div className="col-lg-10 col-md-8 offset-md-2 offset-lg-1">
          <iframe
            width="100%"
            height="415"
            src="https://www.youtube.com/embed/eN3oTx6Wvss"
            title="Embedded Video"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <Row className="text-center">
        <Col md={5} className="offset-md-1  mt-3 offset-xl-2 col-md-4 col-xl-4">
          <Card
            className="d-flex align-items-center h-100"
            style={{ backgroundColor: "#FAF3FE" }}
          >
            {/* <Card.Img src={logo} width={"150px"} height={"150px"}/> */}
            <div className="col-xl-6 col-lg-9 col-md-7 p-4">
              <img src={logo} className="logo" alt="" />
            </div>
            <Card.Body>
              <Card.Title className="fs-3 fw-bolder">
                CarePro Connect - Lite
              </Card.Title>

              <Card.Text className="fs-5 fw-lighter m-2 block px-3 py-2">
                <li className="mt-3">
                  Start your data adventure with 7 QUIERIES PER WEEK !
                </li>
                <li className="mt-3">
                  Discover trends, patterns, and insights effortlessly.
                </li>
                <li className="mt-3">
                  Access our exclusive query library and stay ahead 24 hours a
                  day.
                </li>
                <li className="mt-3">
                  Supercharge your data skills and uncover hidden gems.
                </li>
                <li className="mt-3">
                  Reinforce your administration care team
                </li>
              </Card.Text>
              <Card.Subtitle className=" fw-bolder fs-4">Free</Card.Subtitle>
            </Card.Body>
          </Card>
        </Col>
        <Col md={5} xl={4} className="mt-3">
          <Card
            className="d-flex align-items-center h-100"
            style={{ backgroundColor: "#FAF3FE" }}
          >
            <div className="col-xl-6 col-lg-9 col-md-7 p-4">
              <img src={logo} className="logo" alt="" />
            </div>
            <Card.Body>
              <Card.Title className="fs-3 fw-bolder">
                CarePro Connect - Essentials
              </Card.Title>
              <Card.Text className="fs-5 fw-lighter m-2 block px-3 py-2">
                <li className="mt-3">
                  UNLOCK UNLIMITED INSIGHTS for your data journey!
                </li>
                <li className="mt-3">
                  {" "}
                  Dive deep into data and make informed decisions.
                </li>
                <li className="mt-3">
                  {" "}
                  Find trends, patterns, and opportunities with ease.
                </li>
                <li className="mt-3">
                  Dominate your industry with unlimited data exploration at your
                  finger tips.
                </li>
                <li className="mt-3">
                  Join our supportive community and conquer data together!
                </li>
              </Card.Text>

              <Card.Subtitle className="mb-3 mt-3 fs-4 fw-bolder">
              <span>&#163;</span>14.99/mo &nbsp;
                {voucherApplied && (
                  <p className="fs-5 fw-lighter m-2 purple-text">
                    Voucher applied successfully
                  </p>
                )}{" "}
                {/* Show the div if voucherApplied is true */}
                <OverlayTrigger placement="right" overlay={renderTooltip}>
                  <Button
                    className="purple rounded-circle"
                    onClick={handleShow}
                  >
                    +
                  </Button>
                </OverlayTrigger>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Add Your Voucher Code</Modal.Title>
                  </Modal.Header>
                  <form
                    className="sign-in-form2"
                    onSubmit={handleSubmit(onSubmitHandler)}
                  >
                    <Modal.Body>
                      <label htmlFor="voucherCode">Voucher Code</label>
                      <Controller
                        control={control}
                        name="voucherCode"
                        render={({ field }) => (
                          <Input
                            {...field}
                            invalid={!!errors.voucherCode?.message}
                            placeholder="Enter your Voucher Code"
                          />
                        )}
                      />
                      <div
                        style={{
                          height: "20px",
                          color: "red",
                          padding: "4px 2px",
                          fontSize: "12px",
                        }}
                      >
                        {errors?.voucherCode?.message as any}
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button className="outline" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button className="purple" type="submit">
                        Add
                      </Button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </Card.Subtitle>

              {/* <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                <Button variant="success">+</Button>
              </OverlayTrigger> */}
              {/* <Button className="purple mx-auto py-2 mt-5">Pay Now</Button> */}
              {paymentLink ? (
                <a href={paymentLink} target="_blank" rel="noopener noreferrer">
                  <Button className="purple mx-auto py-2 mt-5">Pay Now</Button>
                </a>
              ) : (
                <p>Loading payment link...</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PricingCards;
