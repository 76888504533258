// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/Archive/card.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
  background-color: rgba(37, 4, 44, 0.5);
  background-blend-mode: multiply;
  height: 35vh; /* Adjust the height as needed */
  width: 100%;
}

.rounded {
  width: 25%;
  height: 15%;
  margin-left: 20%;
  border: 10px;
}

.heading {
  font-size: 50px;
}

.content {
  margin-left: 10%;
}

@media (max-width: 768px) {
  .bg {
    height: 50vh; /* Adjust the height for full-screen on smaller devices */
  }
  .rounded {
    width: 45%;
    height: 10%;
  }

  .heading {
    font-size: 35px;
  }
}

@media (max-width: 425px) {
  .bg {
    height: 60vh;
    width: 106%;
  }
  .content {
    margin-left: 5%;
  }
  .heading {
    font-size: 35px;
  }

  .rounded {
    margin-bottom: 10%;
    width: 45%;
    height: 25%;
    margin-left: -2%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Footer.css"],"names":[],"mappings":"AAAA;EACE,yDAAsD;EACtD,sBAAsB;EACtB,2BAA2B;EAC3B,sCAAsC;EACtC,+BAA+B;EAC/B,YAAY,EAAE,gCAAgC;EAC9C,WAAW;AACb;;AAEA;EACE,UAAU;EACV,WAAW;EACX,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,YAAY,EAAE,yDAAyD;EACzE;EACA;IACE,UAAU;IACV,WAAW;EACb;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,YAAY;IACZ,WAAW;EACb;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,gBAAgB;EAClB;AACF","sourcesContent":[".bg {\n  background-image: url(\"../../assets/Archive/card.jpg\");\n  background-size: cover;\n  background-position: center;\n  background-color: rgba(37, 4, 44, 0.5);\n  background-blend-mode: multiply;\n  height: 35vh; /* Adjust the height as needed */\n  width: 100%;\n}\n\n.rounded {\n  width: 25%;\n  height: 15%;\n  margin-left: 20%;\n  border: 10px;\n}\n\n.heading {\n  font-size: 50px;\n}\n\n.content {\n  margin-left: 10%;\n}\n\n@media (max-width: 768px) {\n  .bg {\n    height: 50vh; /* Adjust the height for full-screen on smaller devices */\n  }\n  .rounded {\n    width: 45%;\n    height: 10%;\n  }\n\n  .heading {\n    font-size: 35px;\n  }\n}\n\n@media (max-width: 425px) {\n  .bg {\n    height: 60vh;\n    width: 106%;\n  }\n  .content {\n    margin-left: 5%;\n  }\n  .heading {\n    font-size: 35px;\n  }\n\n  .rounded {\n    margin-bottom: 10%;\n    width: 45%;\n    height: 25%;\n    margin-left: -2%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
