import React, { Fragment, useState } from "react";
import RecordButton from "./RecordButton";
import StopButton from "./StopButton";
import Box from "@material-ui/core/Box";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import styled from "styled-components";
import Fab from "@material-ui/core/Fab";
import { useDispatch } from "react-redux";
import { setMessage } from "../store/message";
import { CircularProgress } from "@material-ui/core";
import { OpenAIMessage } from "../types";
import { createChatCompletion, createStreamingChatCompletion } from "../openai";
import { useAppContext } from "../context";

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const StyledFab = styled(Fab)`
  position: relative;
  height: 30px;
  width: 36px;
  background-color: ${(props) => (props.recording ? "#272727" : "#8f3934")};
  color: white;
  opacity: 1;
  margin: 12px;
  z-index: 3;
  transition: all 0.2s;
  :hover {
    background-color: ${(props) => (props.recording ? "#272727" : "#8f3934")};
    opacity: 0.9;
  }
`;
const systemPrompt = `
Please read the following exchange and write a short, concise title describing the topic.
`.trim();

const SimpleRecorder = () => {
  const [audioURL, setAudioURL] = useState<any>();
  const context = useAppContext();

  const [isRecording, setIsRecording] = useState(false);
  const [audioLoading, setAudioLoading] = useState(false);
  const dispatch = useDispatch();
  var recorder, gumStream;
  //var recordButton = document.getElementById("recordButton");

  const handleSubmit = async (audio) => {
    try {
      setAudioLoading(true);
      const blob = await new Blob([audio], { type: "audio/m4a" });
      const formData = await new FormData();
      await formData.append("audio", blob, "recording.m4a");
      const { data } = await axios.post(
        "http://13.50.248.188:3000/chatgpt/addFile",
        formData
      );
      await dispatch(setMessage(data?.payload?.text?.text));
      if (
        await context.onNewMessage(
          `${data?.payload?.text?.text} --- Create notes of the above text`,
          true
        )
      ) {
        dispatch(setMessage(""));
      }
      // const messages: OpenAIMessage[] = [
      //   {
      //     role: "system",
      //     content: systemPrompt,
      //   },
      //   {
      //     role: "user",
      //     content: `Create note of the following paragraph "${data?.payload?.text?.text}"`,
      //   },
      // ];

      // let response = await createStreamingChatCompletion(messages as any, {
      //   temperature: 0.5,

      //   apiKey: "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",
      // });
      setAudioLoading(false);
    } catch (error) {
    } finally {
    }
  };

  function toggleRecording() {
    if (recorder && recorder.state === "recording") {
      recorder.stop();
      gumStream.getAudioTracks()[0].stop();
    } else {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then(function (stream) {
          var oldPreviewElm;
          oldPreviewElm = document.getElementById("preview");
          if (oldPreviewElm.hasChildNodes()) {
            oldPreviewElm.removeChild(oldPreviewElm.childNodes[0]);
          }
          gumStream = stream;
          recorder = new MediaRecorder(stream);
          recorder.ondataavailable = function (e) {
            handleSubmit(e.data);

            // var url = URL.createObjectURL(e.data);
            // var preview = document.createElement("audio");
            // preview.style.height = "32px";
            // preview.controls = true;
            // preview.src = url;
            // //console.log("recorder:", recorder);
            // //document.body.appendChild(preview);
            // setAudioURL(preview);
            // var previewElm;
            // previewElm = document.getElementById("preview");
            // if (previewElm.hasChildNodes()) {
            //   previewElm.replaceChild(preview, previewElm.childNodes[0]);
            // } else {
            //   previewElm.appendChild(preview);
            // }
            // console.log(previewElm);
          };
          recorder.start();
        });
    }
  }
  const toggleIsRecording = () => {
    setIsRecording(!isRecording);
  };

  return (
    <div style={{ display: "flex", alignItems: "center", marginRight: "4px" }}>
      {audioLoading ? (
        <Tooltip title="Converting" aria-label="record" placement="right">
          <StyledFab recording color="secondary" aria-label="record">
            <CircularProgress size={24} />
          </StyledFab>
        </Tooltip>
      ) : ''}
      <StyledBox id="preview" />
    </div>
  );
};

export default SimpleRecorder;
