import React, { useState } from "react";
import "../../sign-in-style.css";
import image from "../../assets/Archive/ContactUs/contact-us/amico.png";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import { Input, Textarea } from "@mantine/core";
import logo from "../../assets/logos/thecareprocouk-high-resolution-logo-transparent (1).png";
import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";
import { SOCIAL_CARE } from "../../values";
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";

const FooterContainer = styled.footer`
  background: #000;
  color: white;
  padding: 1rem;
  text-align: center;
  z-index: 2;
  margin-top: auto;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: center;
`;

function ContactUs() {
  const [expanded, setExpanded] = useState(false);

  const toggleNav = () => {
    setExpanded(!expanded);
  };

  const schema = yup.object().shape({
    username: yup.string().required(),
    email: yup.string().email().required(),
    message: yup.string().min(8).required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: "",
      email: "",
      message: "",
    },
  });

  const navigate = useNavigate();

  const onSubmitHandler = async (data) => {
    let contactObj = {
      name: data.username,
      email: data.email,
      message: data.message,
    };

    try {
      const apiUrl = "https://api.thecarepro.co.uk/api/v1/contact-us";

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contactObj),
      };

      const response = await fetch(apiUrl, requestOptions);

      if (response.ok) {
        // localStorage.setItem("cureSpaceUserLogged", JSON.stringify(true));
        toast.success("Details submitted successfully");
        // navigate("/contactUs");
        window.location.reload();
        // reset();
      } else {
        const responseData = await response.json().catch(() => null);
        const errorMessage =
          responseData?.message || "Failed to add details! Try again";
        toast.error(errorMessage[0]);
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <header>
          <nav className={`navbar navbar-expand-lg navbar-dark p-3`}>
            <div className="container-fluid">
              <a className="navbar-brand" href="#">
                <img
                  src={logo}
                  className="nav-logo"
                  width={"100px"}
                  height={"75px"}
                  alt=""
                />
              </a>
              <button
                className={`navbar-toggler ${expanded ? "purple-toggler" : ""}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={toggleNav}
              >
                <span className="navbar-toggler-icon background-black"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a
                      role="button"
                      className={`nav-link montserrat fs-5 text-black`}
                      onClick={() => navigate("/home")}
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      role="button"
                      className={`nav-link montserrat fs-5 text-black`}
                      onClick={() => navigate("/home")}
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      role="button"
                      className={`nav-link montserrat fs-5 text-black`}
                      onClick={() => navigate("/home")}
                    >
                      Services
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      role="button"
                      className={`nav-link montserrat fs-5 text-purple`}
                      onClick={() => navigate("/contactUs")}
                    >
                      Contact
                    </a>
                  </li>
                </ul>
                <div className="d-flex">
                  <button
                    className="outlined-purple me-2 text-black"
                    onClick={() => navigate("/login")}
                  >
                    Sign In
                  </button>
                  <button
                    className="purple"
                    onClick={() => navigate("/register")}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </nav>
        </header>

        <div className="row justify-content-center">
          <div className="col-md-6 image-container">
            <img src={image} alt="" />
          </div>
          <div className="col-md-6 sign-in-screen">
            <div className="welcome-back">
              <h1>Contact Us</h1>
              <p>You have an idea? We handle all the rest.</p>
              <hr />
            </div>
            <form
              className="sign-in-form"
              onSubmit={handleSubmit(onSubmitHandler)}
            >
              <label htmlFor="username">Full Name</label>
              <Controller
                control={control}
                name="username"
                render={({ field }) => (
                  <Input
                    type="text"
                    {...field}
                    invalid={!!errors.username?.message}
                    placeholder="Enter your Full name"
                  />
                )}
              />
              <div
                style={{
                  height: "20px",
                  color: "red",
                  padding: "4px 2px",
                  fontSize: "12px",
                }}
              >
                {errors?.username?.message as any}
              </div>
              <label htmlFor="email">Email</label>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <Input
                    type="text"
                    {...field}
                    invalid={!!errors.email?.message}
                    placeholder="Enter your Email"
                  />
                )}
              />
              <div
                style={{
                  height: "20px",
                  color: "red",
                  padding: "4px 2px",
                  fontSize: "12px",
                }}
              >
                {errors?.email?.message as any}
              </div>
              <label htmlFor="message">Message</label>
              <Controller
                control={control}
                name="message"
                render={({ field }) => (
                  <Textarea
                    className={`textarea ${errors.message ? "is-invalid" : ""}`} // Add 'is-invalid' class if there's an error
                    placeholder="Enter your Message"
                    {...field}
                    onInvalid={() => {}} // Placeholder function for onInvalid event
                  />
                )}
              />
              <div
                style={{
                  height: "20px",
                  color: "red",
                  padding: "4px 2px",
                  fontSize: "12px",
                }}
              >
                {errors?.message?.message as any}
              </div>
              <button type="submit" className="sign-in-button">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <section>
        <FooterContainer>
          <FooterContent>
            <nav
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <Link to="/terms">
                <div>
                  <u className="footer-text text-white fs-6">
                    Terms of Service
                  </u>
                </div>
              </Link>
              <Link to="/privacy">
                <div>
                  <u className="footer-text">Privacy Policy</u>
                </div>
              </Link>
            </nav>
            <SocialMediaContainer style={{ gap: "1.5rem" }} className="mt-3">
              <FaFacebook size={25} color="#4267B2" />
              {/* <FaTwitter size={25} color="#1DA1F2" /> */}
              <FaTiktok size={24} />
              <FaInstagram size={25} color="#E4405F" />
              <FaYoutube size={25} color="#E4405F" />
            </SocialMediaContainer>
            <hr className="horizontal-bar" />

            <div style={{ marginBottom: "8px " }} className="montserrat">
              &copy; {new Date().getFullYear()} {SOCIAL_CARE}. All rights
              reserved.
            </div>
          </FooterContent>
        </FooterContainer>
      </section>
    </>
  );
}

export default ContactUs;
