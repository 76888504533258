import styled from "@emotion/styled";
import { Button } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectOpenAIApiKey } from "../../store/api-keys";
import { openOpenAIApiKeyPanel } from "../../store/settings-ui";
import { Page } from "../page";

const Container = styled.div`
  flex-grow: 1;
  padding-bottom: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //font-family: "Work Sans", sans-serif;
  font-family: "Montserrat";
  line-height: 1.7;
  gap: 1rem;
  padding: 2rem;
`;

const NoteCard = ({ title, content }) => {
  const cardStyle = {
    backgroundColor: "#1a1b1e",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    padding: "16px",
    marginBottom: "16px",
  };

  const titleStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "8px",
  };

  const contentStyle = {
    fontSize: "16px",
    lineHeight: "1.5",
  };

  return (
    <div style={cardStyle}>
      <h2 style={titleStyle}>{title}</h2>
      <p style={contentStyle}>{content}</p>
    </div>
  );
};

export default function Notes(props: any) {
  const [notes, setNotes] = useState([]);
  const openAIApiKey = useAppSelector(selectOpenAIApiKey);
  const dispatch = useAppDispatch();
  const onConnectButtonClick = useCallback(
    () => dispatch(openOpenAIApiKeyPanel()),
    [dispatch]
  );

  const getNotes = async () => {
    let notes: any = (await window.localStorage.getItem("notes")) || "[]";
    notes = JSON.parse(notes);
    setNotes(notes);
  };

  useEffect(() => {
    getNotes();
  }, []);

  const wrapperStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "16px",
    padding: "2rem",
    overflow: "auto",
  };

  return (
    <Page
      style={{ overflowY: "scroll" }}
      id={"landing"}
      showSidebar={false}
      showSubHeader={true}
    >
      <Container>
        <div style={{ height: "100%", width: "100%" }}>
          <h2 style={{ fontSize: "26px", fontWeight: "bold" }}>Notes</h2>
          <div style={wrapperStyle}>
            {notes.map((item: string, index) => {
              return (
                <NoteCard
                  title={item?.slice(0, 32)}
                  content={item}
                  key={index}
                />
              );
            })}
          </div>
        </div>
      </Container>
    </Page>
  );
}
