export const content = `
## About Us 

Welcome to Social Care Expert Assistant, where the future of care begins. Our dedicated team is committed to reshaping the social care landscape by seamlessly integrating technology with compassionate care expertise.
At Social Care Expert Assistant, we understand the challenges of starting and managing a care company. That's why we're here to guide you every step of the way. Our mission is to empower individuals and organizations, making the journey of establishing and running a care company smoother and more successful than ever before.

## Services Overview 
### Discover the Future of Care with Our Virtual Social Care Expert Assistant

🤝 Consultancy for Care Startups: Embark on your journey to establish a care company with confidence. Our experts provide guidance every step of the way, from business planning to compliance. Experience substantial financial savings compared to traditional consultancy services.

📈 General Care Consultancy: Optimize your existing care services with the expertise of our virtual Social Care Expert Assistant. Identify areas for improvement, implement best practices, and enhance client satisfaction. Achieve significant cost savings compared to hiring a full-time professional.

🤖 AI-Powered Insights: Our cutting-edge AI technology provides actionable insights and data-driven recommendations, enabling you to make informed decisions for your care business. Harness the power of AI at a fraction of the cost of hiring a data analyst.

💼 Account and Subscription Management: Stay in control of your subscription and access all the features you need to succeed in the care industry. Our affordable monthly subscription is equivalent to employing a professional for only 2 hours.

🛡️ Privacy and Security: Rest assured knowing that your data is protected with robust privacy and security measures. We prioritize the confidentiality and compliance of your information.
Join us on a journey where compassionate care meets technological innovation. Explore the possibilities with Social Care Expert Assistant today and experience the difference of having a dedicated virtual Social Care Expert Assistant by your side, 24/7, while achieving significant cost savings.
`;
