import React from "react";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBBtn,
} from "mdb-react-ui-kit";
import Card from "react-bootstrap/Card";
import "./ourServices.css";
import image1 from "../../assets/Archive/Services/eos-icons_subscriptions-created.jpg";
import image2 from "../../assets/Archive/Services/fa-solid_handshake.jpg";
import image3 from "../../assets/Archive/Services/fa-solid_robot.jpg";
import image4 from "../../assets/Archive/Services/ic_baseline-privacy-tip.jpg";
import image5 from "../../assets/Archive/Services/octicon_graph-16.jpg";

function OurServices() {
  return (
    <div className="container-fluid mt-5">
      <div className="mb-5 center">
        <h1 className="heading1 fw-bolder">OUR SERVICES</h1>
      </div>
      <div className="row p-5 margin">
        <div className="col-md-12 col-lg-10">
          <div className="row">
            <div className="col-md-4 mt-3">
              <Card className="text-center card1 p-3">
                <div className="row text-center">
                  <div className="col-md-4 offset-md-4">
                    <img src={image2} className="img-fluid" alt="" />
                  </div>
                </div>
                <Card.Body>
                  <Card.Title className="title fs-md-3  fs-lg-1 fw-bold">
                    Consultancy for Care Startups
                  </Card.Title>
                  <Card.Text className="card1-text lh-base fw-lighter justify">
                    Embark on your journey to establish a care company with
                    confidence. Our experts provide guidance every step of the
                    way, from business planning to compliance. Experience
                    substantial financial savings compared to traditional
                    consultancy services.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mt-3">
              <Card className="text-center card1 p-3">
                <div className="col-md-4 offset-md-4">
                  <img src={image5} className="img-fluid" alt="" />
                </div>
                <Card.Body>
                  <Card.Title className="title fs-md-3 fs-lg-1  fw-bold">
                    Care Consultancy
                  </Card.Title>
                  <Card.Text className="card1-text lh-base fw-lighter justify">
                    Optimise your existing care services with the expertise of
                    our virtual TheCarePro. Identify areas for improvement,
                    implement best practices, and enhance client satisfaction.
                    Achieve significant cost savings compared to hiring a
                    full-time professional.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mt-3">
              <Card className="text-center card2 p-3">
                <div className="col-md-4 offset-md-4">
                  <img src={image4} className="img-fluid" alt="" />
                </div>
                <Card.Body>
                  <Card.Title className="title fs-md-3 fs-lg-1  fw-bold">
                    Privacy and Security
                  </Card.Title>
                  <Card.Text className="card2-text lh-base fw-lighter justify">
                    Rest assured knowing that your data is protected with robust
                    privacy and security measures. We prioritise the
                    confidentiality and compliance of your information.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="row mt-3 gap">
            <div className="col-md-4 mt-3">
              <Card className="text-center card1 p-3">
                <div className="col-md-4 offset-md-4">
                  <img src={image3} className="img-fluid" alt="" />
                </div>
                <Card.Body>
                  <Card.Title className="title fs-md-3 fs-lg-1  fw-bold">
                    AI-Powered Insights
                  </Card.Title>
                  <Card.Text className="card1-text lh-base fw-lighter justify">
                    Our cutting-edge AI technology provides actionable insights
                    and data-driven recommendations, enabling you to make
                    informed decisions for your care business. Harness the power
                    of AI at a fraction of the cost of hiring a data analyst.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mt-3">
              <Card className="text-center card1 p-3">
                <div className="col-md-4 offset-md-4">
                  <img src={image1} className="img-fluid" alt="" />
                </div>
                <Card.Body>
                  <Card.Title className="title fs-md-3 fs-lg-1  fw-bold">
                    Account and Subscription Management
                  </Card.Title>
                  <Card.Text className="card1-text lh-base fw-lighter justify">
                    Stay in control of your subscription and access all the
                    features you need to succeed in the care industry. Our
                    affordable monthly subscription is equivalent to employing a
                    professional for only 2 hours.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mt-3">
              <Card className="text-center card1 p-3">
                <div className="col-md-4 offset-md-4">
                  <img src={image3} className="img-fluid" alt="" />
                </div>
                <Card.Body>
                  <Card.Title className="title fs-md-3 fs-lg-1  fw-bold">
                    Dedicated Professional
                  </Card.Title>
                  <Card.Text className="card1-text lh-base fw-lighter justify">
                    Join us on a journey where compassionate care meets
                    technological innovation. Explore the possibilities with
                    TheCarePro today and experience the difference of having a
                    dedicated virtual care professional by your side, 24/7,
                    while achieving significant cost savings.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        {/* <div className="col-md-8 col-lg-4 col-sm-8 top-margin mt-3">
          <Card className="text-center card2 p-3">
            <div className="col-md-4 offset-md-4">
              <img src={image4} className="img-fluid" alt="" />
            </div>
            <Card.Body>
              <Card.Title className="title fs-md-3 fs-lg-1  fw-bold">
                Privacy and Security
              </Card.Title>
              <Card.Text className="card2-text lh-base fw-lighter justify">
                Rest assured knowing that your data is protected with robust
                privacy and security measures. We prioritise the confidentiality
                and compliance of your information.
              </Card.Text>
            </Card.Body>
          </Card>
        </div> */}
      </div>
    </div>
  );
}

export default OurServices;
