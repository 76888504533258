import React from "react";
import "../../sign-in-style.css";
import image from "../../assets/Archive/Login/business-merger/pana.png";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Input } from "@mantine/core";
import * as idb from "idb-keyval";
import { GoogleLogin } from "@react-oauth/google";

function SignInScreen() {
  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().min(8).max(32).required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const navigate = useNavigate();

  const getUserChats = async () => {
    console.log("IN GETE");
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `https://api.thecarepro.co.uk/api/v1/chatgpt/userChat`;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(apiUrl, requestOptions);
      console.log("response :>> ", response);
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };

  const checkUserType = async () => {
    try {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");
      const apiUrl = `https://api.thecarepro.co.uk/api/v1/account/status/${userId}`;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };

  const onSubmitHandler = async (data) => {
    try {
      const apiUrl = "https://api.thecarepro.co.uk/api/v1/account/login";
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(apiUrl, requestOptions);
      const responseData = await response.json();
      console.log("responseData :>> ", responseData);
      if (
        responseData?.payload.message ===
        "Verification email sent successfully!"
      ) {
        toast.success("Verification link sent successfully");
        navigate("/login");
      } else if (response.ok && responseData?.payload.jwt) {
        const token = responseData?.payload.jwt;
        const userName = responseData?.payload.username;
        const userId = responseData?.payload.userId;
        if (token && userId) {
          localStorage.setItem("token", token);
          localStorage.setItem("userId", userId);
          localStorage.setItem("username", userName);
        }
        const userType = await checkUserType();
        localStorage.setItem("userType", userType?.payload?.Free);
        localStorage.setItem("cureSpaceUserLogged", JSON.stringify(true));
        const chats = await getUserChats();
        console.log("chats :>> ", chats);
        await idb.set("chats", chats.payload);
        toast.success("Login success");
        navigate("/");
        return window.location.reload();
      } else {
        if(responseData.payload === 'Already registered with Google Account'){
          toast.error("Please signin with Google");
        }
        else if (responseData.payload === 'Wrong Password'){
          toast.error("Invalid email or password");
        }
        else{
        toast.error("User not exists");
        }
        navigate("/login");
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    console.log(credentialResponse);
    try {
      const apiUrl = "https://api.thecarepro.co.uk/api/v1/account/gmail-auth"
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentialResponse),
      }
      const response = await fetch(apiUrl, requestOptions);
      const responseData = await response.json();
      console.log("responseData: ", responseData);
      if (responseData.payload == 'Already registered with account'){
        return toast.error("Already registered with email");
      }
      if (response.ok && responseData?.payload.jwt) {
        const token = responseData?.payload.jwt;
        const userName = responseData?.payload.username;
        const userId = responseData?.payload.userId;
        if (token && userId) {
          localStorage.setItem("token", token);
          localStorage.setItem("userId", userId);
          localStorage.setItem("username", userName);
          if(responseData?.payload.jwt.image){
            localStorage.setItem("image", responseData.payload.jwt.image);
          }
        }
        const userType = await checkUserType();
        localStorage.setItem("userType", userType?.payload?.Free);
        localStorage.setItem("cureSpaceUserLogged", JSON.stringify(true));
        const chats = await getUserChats();
        await idb.set("chats", chats.payload);
        toast.success("Login success");
        navigate("/");
        return window.location.reload();
      }

      // if (responseData?.payload.success) {
      //   // Handle successful login
      //   toast.success("Google login success");
      //   navigate("/");
      // } else {
      //   toast.error("Google login failed");
      // }
    } catch (error) {
      console.error("Error sending data:", error);
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };

  const handleGoogleFailure = () => {
    toast.error("Google login failed. Please try again later.");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 image-container">
          <img src={image} alt="" />
        </div>
        <div className="col-md-6 sign-in-screen">
          <div className="welcome-back">
            <h1>WELCOME BACK</h1>
            <p>Sign In to enjoy the portal benefits</p>
            <hr />
          </div>
          <form
            className="sign-in-form"
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <label htmlFor="email">Email</label>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <Input
                  type="text"
                  {...field}
                  invalid={!!errors.email?.message}
                  placeholder="Enter your email"
                />
              )}
            />
            <div
              style={{
                height: "20px",
                color: "red",
                padding: "4px 2px",
                fontSize: "12px",
              }}
            >
              {errors?.email?.message as any}
            </div>
            <label htmlFor="password">Password</label>
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <Input
                  type="password"
                  placeholder="Enter your password"
                  {...field}
                  invalid={!!errors.password?.message}
                />
              )}
            />{" "}
            <div
              style={{
                height: "20px",
                color: "red",
                padding: "4px 2px",
                fontSize: "12px",
              }}
            >
              {errors?.password?.message as any}
            </div>
            <div className="forgot-password">
              <a href="#">Forgot your password?</a>
            </div>
            <button type="submit" className="sign-in-button">
              Sign In
            </button>
          </form>
          <div className="sign-up">
            <hr />
            <p>
              Don't have an account?{" "}
              <a role="button" onClick={() => navigate("/register")}>
                <u>Sign Up</u>
              </a>
            </p>
          </div>
          <div>
            <p className="m-4">OR</p>
          </div>
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                handleGoogleSuccess({jwt:credentialResponse.credential});
              }}
              onError={handleGoogleFailure}
            />
            
        </div>
      </div>
    </div>
  );
}

export default SignInScreen;
