import styled from "@emotion/styled";
import { Page } from "../page";
import {
  useStripe,
  useElements,
  Elements,
  CardElement,
} from "@stripe/react-stripe-js";
import StripeCheckout from "react-stripe-checkout";
import { loadStripe } from "@stripe/stripe-js";
import socialCareLogo from "../../assets/logos/socialCarelogo.png";
import { useEffect, useState } from "react";

const Container = styled.div`
  flex-grow: 1;
  padding-bottom: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  line-height: 1.7;
  gap: 1rem;
  padding: 2rem;
`;

const SquareBlock = styled.div`
  background-color: #1a1b1e;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 36px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 450px; /* Set your desired height */
  width: 400px; /* Set your desired width */
`;

const Title = styled.h2`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 8px;
  border-bottom: 3px solid #333;
`;

const Content = styled.p`
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const Button = styled.button`
  background-color: #3c97cf;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
`;

export default function Subscription(props: any) {
  const sec_stripe = localStorage.getItem("token");
  const stripe =
    "sk_test_51ONYgvFUpH08F7FTFqBbm3c0z1dD2HqA12z41dBdb3Zsqy5gkA3ooVxU8COTWPfLrjRRwxd1qEWmjzwmqbIjXup700bClNZZ11";
  const [product, setProduct] = useState({
    payload: {
      paymentId: null,
      productId: null,
    },
  });

  const [paymentLink, setPaymentLink] = useState("");

  const onToken = (token) => {
    fetch("https://api.thecarepro.co.uk/api/v1/stripe/createStripePayment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sec_stripe}`,
      },
      body: JSON.stringify({
        paymentId: product?.payload?.paymentId,
        productId: product?.payload?.productId,
      }),
    })
      .then((response) => response.json())
      .then((paymentData) => {
        console.log("Payment successful:", paymentData);
      })
      .catch((error) => console.error("Error creating payment:", error));
  };

  useEffect(() => {
    fetch(
      "https://api.thecarepro.co.uk/api/v1/stripe/createStripePaymentLink",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sec_stripe}`,
        },
        body: JSON.stringify({}),
      }
    )
      .then((response) => response.json())
      .then((data) => setPaymentLink(data?.payload?.paymentUrl))
      .catch((error) => console.error("Error creating payment link:", error));
  }, []);

  useEffect(() => {
    fetch("https://api.thecarepro.co.uk/api/v1/stripe/createStripeProduct", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sec_stripe}`,
      },
      body: JSON.stringify({
        name: "social care",
        description: "social care",
        images: [
          "https://audiobucket-chatgp3.s3.eu-north-1.amazonaws.com/public_images/WhatsApp+Image+2023-07-26+at+05.12.49.jpg",
        ],
      }),
    })
      .then((response) => response.json())
      .then((data) => setProduct(data))
      .catch((error) => console.error("Error fetching product:", error));
  }, []);

  return (
    <Page
      style={{ overflowY: "scroll" }}
      id={"landing"}
      showSidebar={false}
      showSubHeader={true}
    >
      <Container>
        <div style={{ height: "100%", width: "100%" }}>
          <h2
            style={{
              justifyContent: "center",
              display: "flex",
              fontSize: "26px",
              fontWeight: "bold",
              padding: "20px",
            }}
          >
            Subscription Plans
          </h2>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "16px" }}
          >
            <SquareBlock>
              <Title>Free</Title>
              <Content>
                Unlock a wealth of insights with our premium subscription plan
                offering an ample 7 queries per week. Unleash the power of
                knowledge at your fingertips, enabling a deeper understanding of
                trends, patterns, and opportunities. Stay ahead of the curve
                with exclusive access to our comprehensive query library.
                Subscribe today for an enriched experience, transforming the way
                you engage with data and enhancing your decision-making
                capabilities. Elevate your strategy, optimize your operations,
                and embrace a future driven by valuable, actionable insights.
                Join our community of data enthusiasts and embark on a journey
                of continuous learning and growth. Your data-driven success
                story begins with us!
              </Content>
            </SquareBlock>
            <SquareBlock>
              <Title>Super</Title>
              <Content>
                Experience unlimited access to insights with our premium
                subscription plan, offering an impressive quota of queries every
                month. Dive deep into data, gaining valuable perspectives for
                informed decision-making. Our unlimited plan empowers you with
                unrestricted access to a comprehensive query library, allowing
                you to explore trends, patterns, and opportunities at your pace.
                Stay ahead in your industry with unparalleled insights.
                Subscribe today for an enriched experience, transforming the way
                you engage with data. Elevate your strategy, optimize
                operations, and embark on a journey of continuous learning and
                growth. Join our community of data enthusiasts and unlock
                boundless possibilities in your pursuit of success!
              </Content>
              <ButtonContainer>
                {paymentLink ? (
                  <a
                    href={paymentLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button>Pay Now</Button>
                  </a>
                ) : (
                  <p>Loading payment link...</p>
                )}
                {/* <Button onClick={handleUpgrade}>Upgrade</Button> */}
                {/* <StripeCheckout
                  token={onToken}
                  stripeKey={stripe}
                  name={`Social Care Expert`}
                  description={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam consequuntur veritatis dolor eos tenetur, sequi asperiores quis eaque adipisci quos `}
                  ComponentClass="div"
                  panelLabel="Pay Now!"
                  amount={10000}
                  currency="USD"
                  locale="eng"
                  email="test@test.com"
                  shippingAddress
                  billingAddress={false}
                  image={socialCareLogo}
                  zipCode={false}
                  alipay 
                  bitcoin
                  allowRememberMe 
                /> */}
              </ButtonContainer>
            </SquareBlock>
          </div>
        </div>
      </Container>
    </Page>
  );
}
