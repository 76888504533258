// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li::marker {
  color: rgb(205, 0, 205);
  font-size: 1.75rem;
}


#button-tooltip:hover{
    height: 50px;
    background-color: #F6E9FE !important;
    color: #B026FF;
}

@media (min-width: 425px) and (max-width: 770px) {
  .logo {
    width: 100px;
    height: 100px;
  }

  li::marker {
    color: rgb(205, 0, 205);
    font-size: 1.25rem;
  }
}


.block{
  display: block;
  text-align: justify;
}

.modal-header .btn-close{
  background-color: #B026FF;
  color: white;
  border-radius:20px;
}

.modal-header {
  border-bottom: none; /* Remove the border below the header */
}

.modal-footer {
  border-top: none; /* Remove the border below the header */
}

.outline{
  background-color: white;
  color: grey;
}


.purple-text{
  color: #B026FF;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/subscription.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;AACpB;;;AAGA;IACI,YAAY;IACZ,oCAAoC;IACpC,cAAc;AAClB;;AAEA;EACE;IACE,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,uBAAuB;IACvB,kBAAkB;EACpB;AACF;;;AAGA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,mBAAmB,EAAE,uCAAuC;AAC9D;;AAEA;EACE,gBAAgB,EAAE,uCAAuC;AAC3D;;AAEA;EACE,uBAAuB;EACvB,WAAW;AACb;;;AAGA;EACE,cAAc;AAChB","sourcesContent":["li::marker {\n  color: rgb(205, 0, 205);\n  font-size: 1.75rem;\n}\n\n\n#button-tooltip:hover{\n    height: 50px;\n    background-color: #F6E9FE !important;\n    color: #B026FF;\n}\n\n@media (min-width: 425px) and (max-width: 770px) {\n  .logo {\n    width: 100px;\n    height: 100px;\n  }\n\n  li::marker {\n    color: rgb(205, 0, 205);\n    font-size: 1.25rem;\n  }\n}\n\n\n.block{\n  display: block;\n  text-align: justify;\n}\n\n.modal-header .btn-close{\n  background-color: #B026FF;\n  color: white;\n  border-radius:20px;\n}\n\n.modal-header {\n  border-bottom: none; /* Remove the border below the header */\n}\n\n.modal-footer {\n  border-top: none; /* Remove the border below the header */\n}\n\n.outline{\n  background-color: white;\n  color: grey;\n}\n\n\n.purple-text{\n  color: #B026FF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
