import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { APP_NAME, SOCIAL_CARE } from "../values";
import { useAppContext } from "../context";
import { Link, useNavigate, useParams } from "react-router-dom";
import socialCareLogo from "../assets/logos/socialCarelogo.png";
import socialCareLogoNew from "../assets/logos/thecareprocouk-high-resolution-logo-transparent (1).png";
import chatIcon from "../assets/logos/flowbite_edit-outline.png";
import "./settings/sidebar.css";
import * as idb from "../idb";

const Sidebar = ({ onChildCallback }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [showSub, setShowSub] = useState(false);

  const [userActive, setUserActive] = useState(true);

  useEffect(() => {
    console.log("Setting up logout timer...");
    // Set timer for automatic logout after 10 minutes of inactivity
    let logoutTimer = setTimeout(() => {
      setUserActive(false);
      console.log("5 seconds passed. Logging out...");
      handleLogout();
    }, 10 * 60 * 1000); // 5 seconds in milliseconds

    // Reset timer on user activity
    const resetTimer = () => {
      clearTimeout(logoutTimer);
      console.log("Timer reset.");
      // Set up the timer again
      logoutTimer = setTimeout(() => {
        setUserActive(false);
        console.log("5 seconds passed. Logging out...");
        handleLogout();
      }, 10 * 60 * 1000); // 5 seconds in milliseconds
    };

    // Add event listeners for user activity
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    // Clear user activity listeners and timer on component unmount
    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      clearTimeout(logoutTimer);
    };
  }, []);

  const AppName = styled.h2`
    color: white;
    font-weight: bold;
    margin: 0;
    font-family: "Montserrat";
  `;
  const handleClick = () => {
    setShowSub(showSub);
    onChildCallback(showSub);
  };

  const handleLogout = async () => {
    await idb.del("chats");
    localStorage.setItem("cureSpaceUserLogged", JSON.stringify(false));
    window.location.reload();
    // localStorage.removeItem("token");
    // localStorage.removeItem("cureSpaceUserLogged");
    // localStorage.removeItem("userType");
    // localStorage.removeItem("userId");
    // localStorage.removeItem("username");
    localStorage.clear();
  };
  const context: any = useAppContext();
  const navigate = useNavigate();

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const handleToggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const onNewChat = useCallback(async () => {
    setLoading(true);
    if (window.innerWidth <= 768) {
      // Detect mobile screen width
      setSidebarOpen(false); // Close sidebar on mobile screens
    }
    navigate(`/`);
    setLoading(false);
  }, [navigate]);

  return (
    <>
      <div className={`bar ${isSidebarOpen ? "open" : ""}`}>
        <h2
          style={{
            padding: "16px 12px",
            color: "white",
            fontWeight: "bold",
          }}
        >
          <div className="logo-container">
            <Link
              style={{
                textDecoration: "none",
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
              to="/"
            >
              <img src={socialCareLogoNew} alt="Logo" className="logo" />
            </Link>
          </div>
        </h2>
        <button className="purple new-chat text-white" onClick={onNewChat}>
          <img src={chatIcon} className="icon-chat" alt="" />
          <span className="font-size">New Chat</span>
        </button>
        <div
          className="p-2"
          style={{
            height: "70vh",
            overflowY: "auto",
          }}
        >
          {context.chat.search.query()?.map?.((item, index) => {
            return (
              <div
                key={index}
                className="card-sidebar"
                onClick={() => {
                  if (window.innerWidth <= 768) {
                    // Close sidebar on mobile screens
                    console.log("IIIIIIIIIII");
                    setSidebarOpen(false);
                  }
                  navigate(
                    "/chat/" +
                      item.chatID +
                      (item.messageID ? "#msg-" + item.messageID : "")
                  );
                }}
              >
                <h2 className="font-size fw-bold">{item?.title}</h2>
              </div>
            );
          })}
        </div>

        <div id="logout_sidebar_button">
          <button
            className="black new-chat-black text-white font-size"
            onClick={handleClick}
          >
            <img src={chatIcon} className="icon-chat" alt="" />
            Upgrade Package
          </button>
          <button
            className="black new-chat-black text-white font-size"
            onClick={handleLogout}
          >
            <img src={chatIcon} className="icon-chat" alt="" />
            Sign Out
          </button>
        </div>
      </div>
      <div className="hamburger-menu" onClick={handleToggleSidebar}>
        ☰
      </div>
    </>
  );
};

export default Sidebar;
