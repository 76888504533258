import { useEffect } from "react";
import { MDBIcon } from "mdb-react-ui-kit";
import "./carousal.css";
import dot1 from "../../assets/logos/Group.png";
import dot2 from "../../assets/logos/Group-1.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import getStartedLogo from "../../assets/logos/material-symbols_play-circle-outline-rounded.png";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../assets/Archive/default.jpg";
import reviewOne from "../../assets/Testimonials/Emily .png";
import reviewTwo from "../../assets/Testimonials/Mark.png";
import reviewThree from "../../assets/Testimonials/Rachel.png";
import reviewFour from "../../assets/Testimonials/Tsitsi.png";
import reviewFive from "../../assets/Testimonials/Phillip.png";
import reviewSix from "../../assets/Testimonials/Sarah Thompson.png";


export default function MyCarousel() {
  useEffect(() => {
    // Suppress the warning for OwlCarousel
    console.warn = () => {};
  }, []);

  const navigate = useNavigate();

  return (
    <div className="container-fluid container-padding background">
      <div className="mb-5 center mt-3">
        <h1 className="heading1 fw-bolder">WHAT OUT CLIENTS SAY</h1>
      </div>
      <OwlCarousel
        className="owl-theme"
        responsive={{
          0: {
            items: 1, // Display one item on small screens
          },
          600: {
            items: 2, // Display two items on screens greater than 600 pixels
          },
          1000: {
            items: 2, // Display three items on screens greater than 1000 pixels
          },
        }}
      >
        <div className="item">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10 offset-md-1 p-5">
              <img
                className="dot1"
                src={dot1}
                width={"100px"}
                height={"75px"}
                alt=""
              />
              <img
                className="dot2"
                src={dot2}
                width={"100px"}
                height={"75px"}
                alt=""
              />
              <ul className="star">
                4.5
                <MDBIcon className="text-warning" fas icon="star" size="sm" />
              </ul>
              <div className="row ">
                <div className="col-md-4 col-lg-4 margin-left">
                  <img
                    src={reviewOne}
                    alt="avatar"
                    className="rounded-circle shadow-1-strong mb-4 img-fluid"
                  />
                </div>
                <div className="col-md-7 col-lg-4 mt-5 heading-margin">
                  <h5 className="mb-1">Emily L.</h5>
                  {/* <p>C T O | Smiletime Inc</p> */}
                </div>
              </div>

              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <p className="text-muted lh-base fw-lighter">
                    I can't imagine running my care agency without TheCarePro.
                    It has simplified my workload, saved me countless hours, and
                    allowed me to focus more on providing exceptional care to my
                    clients. A game-changer for anyone in the care sector!"
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10 p-5">
              <img
                className="dot1"
                src={dot1}
                width={"100px"}
                height={"75px"}
                alt=""
              />
              <img
                className="dot2"
                src={dot2}
                width={"100px"}
                height={"75px"}
                alt=""
              />
              <ul className="star">
                4.5
                <MDBIcon className="text-warning" fas icon="star" size="sm" />
              </ul>
              <div className="row ">
                <div className="col-md-5 col-lg-4 margin-left">
                  <img
                    src={reviewTwo}
                    alt="avatar"
                    className="rounded-circle shadow-1-strong mb-4 img-fluid"
                  />
                </div>
                <div className="col-md-7 col-lg-4 mt-5 heading-margin">
                  <h5 className="mb-1">Mark D.</h5>
                  {/* <p>C T O | Smiletime Inc</p> */}
                </div>
              </div>

              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <p className="text-muted lh-base fw-lighter">
                    TheCarePro has been an absolute lifesaver for our care team.
                    Its comprehensive features and user-friendly interface have
                    significantly improved our efficiency and productivity.
                    Highly recommend giving it a try!"
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10 offset-md-1 p-5">
              <img
                className="dot1"
                src={dot1}
                width={"100px"}
                height={"75px"}
                alt=""
              />
              <img
                className="dot2"
                src={dot2}
                width={"100px"}
                height={"75px"}
                alt=""
              />
              <ul className="star">
                4.5
                <MDBIcon className="text-warning" fas icon="star" size="sm" />
              </ul>
              <div className="row ">
                <div className="col-md-4 col-lg-4 margin-left">
                  <img
                    src={reviewThree}
                    alt="avatar"
                    className="rounded-circle shadow-1-strong mb-4 img-fluid"
                  />
                </div>
                <div className="col-md-7 col-lg-4 mt-5 heading-margin">
                  <h5 className="mb-1">Rachel S</h5>
                  {/* <p>C T O | Smiletime Inc</p> */}
                </div>
              </div>

              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <p className="text-muted lh-base fw-lighter">
                    I've been using TheCarePro for a few months now, and I'm
                    incredibly impressed with its functionality and ease of use.
                    From drafting client care plans to managing meetings, it has
                    exceeded my expectations. A must-have tool for anyone in the
                    care industry!"
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10 offset-md-1 p-5">
              <img
                className="dot1"
                src={dot1}
                width={"100px"}
                height={"75px"}
                alt=""
              />
              <img
                className="dot2"
                src={dot2}
                width={"100px"}
                height={"75px"}
                alt=""
              />
              <ul className="star">
                4.5
                <MDBIcon className="text-warning" fas icon="star" size="sm" />
              </ul>
              <div className="row ">
                <div className="col-md-4 col-lg-4 margin-left">
                  <img
                    src={reviewFour}
                    alt="avatar"
                    className="rounded-circle shadow-1-strong mb-4 img-fluid"
                  />
                </div>
                <div className="col-md-7 col-lg-4 mt-5 heading-margin">
                  <h5 className="mb-1">Tsitsi M</h5>
                  {/* <p>C T O | Smiletime Inc</p> */}
                </div>
              </div>

              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <p className="text-muted lh-base fw-lighter">
                    I was first unsure about using Ai in my company, when I was
                    shown how I can use it for various administration tasks I
                    saw the real value. Would definitely recommend it for any
                    admin tasks. Really worth the money.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10 offset-md-1 p-5">
              <img
                className="dot1"
                src={dot1}
                width={"100px"}
                height={"75px"}
                alt=""
              />
              <img
                className="dot2"
                src={dot2}
                width={"100px"}
                height={"75px"}
                alt=""
              />
              <ul className="star">
                4.5
                <MDBIcon className="text-warning" fas icon="star" size="sm" />
              </ul>
              <div className="row ">
                <div className="col-md-4 col-lg-4 margin-left">
                  <img
                    src={reviewFive}
                    alt="avatar"
                    className="rounded-circle shadow-1-strong mb-4 img-fluid"
                  />
                </div>
                <div className="col-md-7 col-lg-4 mt-5 heading-margin">
                  <h5 className="mb-1">Phillip M.</h5>
                  {/* <p>C T O | Smiletime Inc</p> */}
                </div>
              </div>

              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <p className="text-muted lh-base fw-lighter">
                    When I start my day I open our care management software and
                    thecarepro. User-friendly and professional.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10 offset-md-1 p-5">
              <img
                className="dot1"
                src={dot1}
                width={"100px"}
                height={"75px"}
                alt=""
              />
              <img
                className="dot2"
                src={dot2}
                width={"100px"}
                height={"75px"}
                alt=""
              />
              <ul className="star">
                4.5
                <MDBIcon className="text-warning" fas icon="star" size="sm" />
              </ul>
              <div className="row ">
                <div className="col-md-4 col-lg-4 margin-left">
                  <img
                    src={reviewSix}
                    alt="avatar"
                    className="rounded-circle shadow-1-strong mb-4 img-fluid"
                  />
                </div>
                <div className="col-md-7 col-lg-4 mt-5 heading-margin">
                  <h5 className="mb-1">Sarah D</h5>
                  {/* <p>C T O | Smiletime Inc</p> */}
                </div>
              </div>

              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <p className="text-muted lh-base fw-lighter">
                    I still do not fully understand how it works, but all I can
                    say it definitely does work. I am getting a lot more things
                    done.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
      <button
        className="px-3 py-2 purple d-flex started align-items-center text-white mx-auto mt-5"
        onClick={() => navigate("/login")}
      >
        <img src={getStartedLogo} alt="" />{" "}
        <span className="px-2">Get Started</span>
      </button>
    </div>
  );
}
